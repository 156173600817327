import { Component, h } from 'preact';
import NoticeBar from './index.view';
import { useContextOverlay } from '../OverlayContext';
import { useContextConfig } from '../../utils/context/config';
import { useContextGA } from '../../utils/context/gaTracker';
import { useContextLanguage } from '../../locales';
import { acceptCookies } from '../../service/cookie';

class NoticeBarContainer extends Component {
  onAccept = () => {
    const context = useContextOverlay();
    const config = useContextConfig();
    const ga = useContextGA();
    acceptCookies(null, ga.props.ga, config.props.config);
    context.setIsShowNoticeBar(false);
    context.setIsShowBackground(false);
    this.props.onApply();

    setTimeout(() => {
      context.setIsShowToggleButton(false);
      context.disableScroll(false);
    }, 300);
  };

  onClickedManage = () => {
    const context = useContextOverlay();
    context.setIsShowModal(true);
  };

  render() {
    const context = useContextOverlay();
    const language = useContextLanguage();
    return (
      <NoticeBar
        noticeBarDisplay={context.isShowNoticeBar}
        onClickedManage={this.onClickedManage}
        isOpen={context.isShowModal}
        onAccept={this.onAccept}
        t={language.t}
        policyLink={this.props.policyLink}
        showPolicyLink={this.props.showPolicyLink}
        showSetting={this.props.showSetting}
      />
    );
  }
}

export default NoticeBarContainer;

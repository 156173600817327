import { Component, render, h } from 'preact';
import NoticeBarContainer from './components/NoticeBar/index';
import GlobalStyled from './GlobalStyled';
import styled, { ThemeProvider } from 'styled-components';
import getTheme from './styles/theme';
import ModalContainer from './components/Modal';
import GaTracker from './utils/context/gaTracker';
import CookiesConfig from './utils/context/config';
import OverlayState from './components/OverlayContext';
import OverlayContainer from './components/Overlay';
import ToggleButtonContainer from './components/ToggleButton';
import { LanguageState, contextLanguageFucntion } from './locales';
import { checkGoogleAnalytic } from './service/cookie';

const Wrapper = styled.div``;

class App extends Component {
  componentDidMount() {
    checkGoogleAnalytic(this.props.config);
  }

  render() {
    const ga = this.props.ga || window.ga;
    return (
      <ThemeProvider
        theme={() => getTheme(this.props.colorList, this.props.fontFamily)}
      >
        <Wrapper>
          <LanguageState locale={this.props.locale}>
            <CookiesConfig config={this.props.config}>
              <GaTracker ga={ga}>
                <OverlayState>
                  <GlobalStyled />
                  <ToggleButtonContainer
                    isShowPopupWhenClickToggle={
                      this.props.isShowPopupWhenClickToggle
                    }
                  />
                  <NoticeBarContainer
                    policyLink={this.props.policy}
                    showSetting={this.props.showSetting}
                    showPolicyLink={this.props.showPolicyLink}
                    onApply={() => {
                      if (this.props?.onSettingChange) {
                        this.props.onSettingChange();
                      }
                    }}
                  />
                  <ModalContainer
                    onSave={() => {
                      if (this.props?.onSettingChange) {
                        this.props.onSettingChange();
                      }
                    }}
                  />
                  <OverlayContainer />
                </OverlayState>
              </GaTracker>
            </CookiesConfig>
          </LanguageState>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const renderWeb = ({
  locale,
  performance,
  googleAnalytic,
  marketing,
  ga,
  colorPrimary,
  colorSecondary,
  iconColor,
  hoverColor,
  policy,
  fontFamily,
  essential,
  fbq,
  fbPixel,
  showPolicyLink = true,
  showSetting = true,
  settingOptions = ['essential', 'performance', 'googleAnalytic', 'marketing'],
  onSettingChange,
  isShowPopupWhenClickToggle = false
}) => {
  const element = document.createElement('div');
  element.id = 'cookie-handler-gdpr';
  element.className = 'cookie-handler-gdpr';
  render(
    <App
      locale={locale}
      ga={ga}
      config={{
        essential,
        performance,
        googleAnalytic,
        marketing,
        fbq,
        fbPixel,
        settingOptions
      }}
      colorList={{ colorPrimary, colorSecondary, hoverColor, iconColor }}
      policy={policy}
      showPolicyLink={showPolicyLink}
      showSetting={showSetting}
      fontFamily={fontFamily}
      onSettingChange={onSettingChange}
      isShowPopupWhenClickToggle={isShowPopupWhenClickToggle}
    />,
    element
  );
  document.body.appendChild(element);

  return { setLanguage };
};

const setLanguage = locale => {
  const languageContext = contextLanguageFucntion();
  languageContext().getLanguageData(locale);
};

export default renderWeb;

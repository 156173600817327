import { gdprCookieNotice } from './index';
import ReactGA from 'react-ga';

if (process.env.NODE_ENV === 'production') {
  window.gdprCookieNotice = gdprCookieNotice;
} else {
  document.addEventListener('DOMContentLoaded', () => {
    ReactGA.initialize('UA-151166943-1'); //For use in dev environment
    const gdpr = gdprCookieNotice({
      essential: ['PHPSSID', 'RAM'],
      performance: ['JSESSIONID'],
      googleAnalytic: 'UA-151166943-1',
      marketing: ['SSID'],
      ga: ReactGA.ga(),
      locale: 'en',
      colorPrimary: '#4BAA59',
      colorSecondary: '',
      hoverColor: '#6CC04A',
      iconColor: '#ffffff',
      policy: 'https://dist.elecwebmaker.now.sh/',
      showPolicyLink: true,
      showSetting: true,
      settingOptions: ['essential', 'googleAnalytic'],
      isShowPopupWhenClickToggle: true,
      onSettingChange: () => {
        console.log('onSettingChange');
      }
    });
  });
}

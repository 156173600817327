import {
  createFontStyle,
  createFontSizeStyle,
  createFontWeightStyle,
  createLineHeight,
  createFontFamily
} from './utils/typography';

const createUserFontFamily = () => createFontFamily('', true);

const typography = {
  button: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('700'),
    createLineHeight('21px')
  ),
  title: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(18),
    createFontWeightStyle('700'),
    createLineHeight('28px')
  ),
  moblieTitle: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('700'),
    createLineHeight('28px')
  ),
  description: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('400'),
    createLineHeight('20px')
  ),
  mobileDescription: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(11),
    createFontWeightStyle('400'),
    createLineHeight('20px')
  ),
  body: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(16),
    createFontWeightStyle('400'),
    createLineHeight('24px')
  ),
  bodyLargeHighlight: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(18),
    createFontWeightStyle('700'),
    createLineHeight('21px')
  ),
  link: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(12),
    createFontWeightStyle('700'),
    createLineHeight('')
  ),
  mobileLink: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(11),
    createFontWeightStyle('700'),
    createLineHeight('')
  ),
  field: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(16),
    createFontWeightStyle('400'),
    createLineHeight('24px')
  ),
  cookieTitle: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('700'),
    createLineHeight('14px')
  ),
  cookieDescription: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(11),
    createFontWeightStyle('400'),
    createLineHeight('16px')
  ),
  modalTitle: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(18),
    createFontWeightStyle('700'),
    createLineHeight('18px')
  ),
  cookieLabel: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('600'),
    createLineHeight('21px')
  ),
  toggleButton: createFontStyle(
    createUserFontFamily(),
    createFontSizeStyle(14),
    createFontWeightStyle('700'),
    createLineHeight('18px')
  )
};

export default typography;

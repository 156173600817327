export const colorSet = colorList => {
  const color = {
    primary: colorList.colorPrimary || '#000000',
    secondary: colorList.colorSecondary || '#ffffff',
    cookieColor: colorList.iconColor || '#ffffff',
    hoverColor: colorList.hoverColor,
    lightBlue: '#3578e5',
    white: '#ffffff',
    grey: '#f2f2f2',
    lightGray: '#606770',
    gray77: '#c4c4c4',
    blackPearl: '#1c1e21',
    blackRussian: '#202124',
    error: 'red',
    black: '#000000',
    fruitSalad: '#4baa59',
    whisper: '#eaeaea',
    silver: '#bdbdbd'
  };

  return color;
};

export const elementColor = {
  button: colorSet.primary,
  buttonText: colorSet.white,
  buttonOutline: colorSet.white,
  link: colorSet.lightBlue
};

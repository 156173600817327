import { h, createContext } from 'preact';
import { useContext } from 'preact/compat';

const CookieConfig = createContext();

const CookiesConfig = ({ ...props }) => {
  return (
    <CookieConfig.Provider value={{ props }}>
      {props.children}
    </CookieConfig.Provider>
  );
};

export const useContextConfig = () => {
  const config = useContext(CookieConfig);
  return config;
};

export default CookiesConfig;

import { h, Component } from 'preact';
import { useContextOverlay } from '../OverlayContext';
import Overlay from './index.view';

class OverlayContainer extends Component {
  render() {
    const context = useContextOverlay();
    return <Overlay isShowBackground={context.isShowBackground}></Overlay>;
  }
}

export default OverlayContainer;

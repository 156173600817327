import { h, createContext } from 'preact';
import { useContext } from 'preact/compat';

const GaContext = createContext();

const GaTracker = ({ ...props }) => {
  return (
    <GaContext.Provider value={{ props }}>{props.children}</GaContext.Provider>
  );
};

export const useContextGA = () => {
  const ga = useContext(GaContext);
  return ga;
};

export default GaTracker;

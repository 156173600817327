import { h } from 'preact';
import styled from 'styled-components';

const CookieWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25px;
  fill: ${({ theme }) => theme.color.cookieColor};
`;

const CookieIcon = () => {
  return (
    <CookieWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.9306 11.9916C20.6507 11.9518 18.0023 9.28743 18.0023 5.99821C14.7131 5.99821 12.0492 3.35024 12.0089 0.0708667C10.7311 -0.123196 9.42465 0.0849292 8.272 0.671804L5.03153 2.32274C3.865 2.91723 2.9167 3.86586 2.32262 5.03259L0.677309 8.26134C0.0814596 9.43103 -0.128898 10.7593 0.0763714 12.0559L0.642621 15.631C0.847687 16.9263 1.45742 18.1235 2.3845 19.051L4.95184 21.6179C5.87598 22.5423 7.06909 23.1505 8.36012 23.3551L11.9559 23.9246C13.2454 24.129 14.5668 23.9195 15.7307 23.3265L18.9712 21.676C20.1376 21.0814 21.0859 20.1328 21.6801 18.9662L23.3254 15.7374C23.9142 14.5824 24.1228 13.2727 23.9306 11.9916ZM8.24997 17.2501C7.42168 17.2501 6.74997 16.5784 6.74997 15.7501C6.74997 14.9218 7.42168 14.2501 8.24997 14.2501C9.07825 14.2501 9.74997 14.9218 9.74997 15.7501C9.74997 16.5784 9.07825 17.2501 8.24997 17.2501ZM9.74997 9.75009C8.92168 9.75009 8.24997 9.07837 8.24997 8.25009C8.24997 7.4218 8.92168 6.75009 9.74997 6.75009C10.5782 6.75009 11.25 7.4218 11.25 8.25009C11.25 9.07837 10.5782 9.75009 9.74997 9.75009ZM17.25 15.7501C16.4217 15.7501 15.75 15.0784 15.75 14.2501C15.75 13.4218 16.4217 12.7501 17.25 12.7501C18.0782 12.7501 18.75 13.4218 18.75 14.2501C18.75 15.0784 18.0782 15.7501 17.25 15.7501Z" />
      </svg>
    </CookieWrapper>
  );
};

export default CookieIcon;

export const language = [
  {
    locale: 'en',
    noticeBarTitle: 'This website uses cookies',
    noticeBarDescription:
      'We use cookies to offer you a better browsing experience, personalize content and ads, provide social media features and analyze our traffic. Read about how we use cookies and how you can control them by clicking Manage Cookies. You consent to our cookies if you continue to use this website. ',
    settingButton: 'Manage Cookies',
    policyButton: 'Learn more',
    acceptButton: 'Accept & Continue',
    saveButton: 'Save Changes',
    toggleButtonFirst: 'Cookies',
    toggleButtonSecond: 'Settings',
    modalTitle: 'Manage Cookies',
    essentialCookieTitle: 'Essential website cookies',
    performanceCookieTitle: 'Performance cookies',
    analyticsCookieTitle: 'Analytics cookies',
    marketingCookieTitle: 'Marketing cookies',
    essentialCookieseDescription:
      'Necessary cookies help make a website usable by enabling basic functions such as page navigation and access to protected areas of the website. Without these cookies, the website cannot function properly.',
    performanceCookieDescription:
      'These cookies are used to enhance the performance and functionality of our websites but are non-essential to their use. For example it stores your preferred language or the region that you are in.',
    analyticsCookieDescription:
      'We use analytics cookies to measure how users interact with website content, which helps us customize our websites and apps for you to improve your experience.',
    marketingCookieDescription:
      'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
    alwaysOn: 'Always on'
  },

  {
    locale: 'de',
    noticeBarTitle: 'Diese Webseite verwendet Cookies',
    noticeBarDescription:
      'Wir verwenden Cookies, um Ihnen ein besseres Browser Erlebnis bieten zu können, Inhalte und Anzeigen zu personalisieren, Ihnen Zugang zu sozialen Medien zu ermöglichen und den Website-Traffic zu analysieren. Für weitere Informationen darüber, wie wir Cookies verwenden und wie Sie diese selbst verwalten können, klicken Sie auf Cookie-Einstellungen. Sie stimmen der Verwendung von Cookies zu, wenn Sie diese Webseite und deren Angebote weiter nutzen.',
    settingButton: 'Cookies Verwalten',
    policyButton: 'Weitere Infomationen',
    acceptButton: 'Akzeptieren und Fortfahren',
    saveButton: 'Änderungen Speichern',
    toggleButtonFirst: 'Cookie',
    toggleButtonSecond: 'Einstellungen',
    modalTitle: 'Cookie Einstellungen',
    essentialCookieTitle: 'Notwendige Cookies für die Webseite',
    performanceCookieTitle: 'Performance Cookies',
    analyticsCookieTitle: 'Analytics Cookies',
    marketingCookieTitle: 'Marketing Cookies',
    essentialCookieseDescription:
      'Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Diese Webseite kann ohne diese Cookies nicht richtig funktionieren.',
    performanceCookieDescription:
      'Diese Cookies werden verwendet, um die Leistung und Funktionalitäten unserer Website zu verbessern, sind aber für deren Verwendung nicht erforderlich. Diese speichern zum Beispiel Ihre bevorzugte Sprache oder die Region, in der Sie sich befinden.',
    analyticsCookieDescription:
      'Wir verwenden Analytik-Cookies, um zu verstehen, wie Nutzer mit Webseite-Inhalten interagieren. Mit diesen Cookies können wir unsere Webseiten und Apps für Sie anpassen, um die Nutzerfreundlichkeit zu verbessern.',
    marketingCookieDescription:
      'Diese Cookies werden verwendet, um Anzeigen zu personalisieren. Ziel ist es, Anzeigen zu zeigen, die für den einzelnen Nutzer relevant und ansprechend sind und damit für Publisher und Dritte wertvoller sind.',
    alwaysOn: 'Immer eingeschaltet'
  }
];

import { Component, h } from 'preact';
import ToggleButton from './index.view';
import { useContextOverlay } from '../OverlayContext';
import { useContextLanguage } from '../../locales/index';

class ToggleButtonContainer extends Component {
  state = { enter: 'transform: translateY(calc(100% - 24px))' };

  toggleButton = () => {
    this.setState({ enter: 'transform: translateY(0)' });
    const context = useContextOverlay();

    if (this.props.isShowPopupWhenClickToggle) {
      setTimeout(() => {
        context.setIsShowModal(true);
      }, 200);
    } else {
      setTimeout(() => {
        context.setIsShowNoticeBar(true);
        context.setIsShowBackground(true);
      }, 200);
      context.setIsShowToggleButton(true);
    }
    context.disableScroll(true);
  };

  render() {
    const context = useContextOverlay();
    const language = useContextLanguage();
    return (
      <ToggleButton
        toggleButton={this.toggleButton}
        transform={context.isShowToggleButton}
        t={language.t}
      />
    );
  }
}

export default ToggleButtonContainer;

import { h, Component } from 'preact';
import styled from 'styled-components';

const Background = styled.div`
  display: ${props => (props.isShowBackground ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background-color: ${({ theme }) => theme.color.black};
  opacity: 0.6;
`;

class Overlay extends Component {
  render() {
    return (
      <Background isShowBackground={this.props.isShowBackground}></Background>
    );
  }
}

export default Overlay;

import { css } from 'styled-components';

export const createFontSizeStyle = size => () =>
  `
    font-size: ${size}px;
`;

export const createFontWeightStyle = weight => () =>
  `
    font-weight: ${weight};
`;

export const createLineHeight = lineheight => () =>
  `line-height: ${lineheight};`;

export const createLetterSpacing = letterSpacing => () =>
  `letter-spacing: ${letterSpacing};`;

export const createFontStyle =
  (...fontStyleFns) =>
  () =>
    css`
      ${({ theme }) => {
        return fontStyleFns
          .map(fontStyleFn => fontStyleFn(theme.fontFamily))
          .join('');
      }}
    `;

export const createFontFamily = () => fontFamily =>
  fontFamily ? `font-family: ${fontFamily};` : null;

import { language } from './language';
import { h, createContext } from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';

const LanguageContext = createContext();

export const LanguageState = ({ ...props }) => {
  const [languageSet, setLanguage] = useState(language[1]);

  const getLanguageData = data => {
    const locale = data || props.locale;
    const languageData = language.find(lang => locale === lang.locale);
    if (languageData) {
      setLanguage(languageData);
    }
  };

  useEffect(() => {
    getLanguageData();
  }, []);

  const t = wording => {
    for (let i = 0; i < Object.keys(languageSet).length; i++) {
      if (Object.keys(languageSet)[i] === wording) {
        return Object.values(languageSet)[i];
      }
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        t,
        setLanguage,
        getLanguageData
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export const useContextLanguage = () => {
  const context = useContext(LanguageContext);
  return context;
};

export const contextLanguageFucntion = () => {
  return useContextLanguage;
};

import { h, createContext } from 'preact';
import { useContext, useState, useEffect, useRef } from 'preact/hooks';
import { getCookies, acceptCookies } from '../service/cookie';
import { useContextGA } from '../utils/context/gaTracker';
import { useContextConfig } from '../utils/context/config';

const OverlayContext = createContext();

const OverlayState = ({ ...props }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowNoticeBar, setIsShowNoticeBar] = useState(false);
  const [isShowBackground, setIsShowBackground] = useState(false);
  const [isShowToggleButton, setIsShowToggleButton] = useState(false);
  const [isDisableScroll, setIsDisableScroll] = useState(false);
  const intervalRef = useRef();
  const ga = useContextGA();
  const config = useContextConfig();

  useEffect(() => {
    if (!getCookies()) {
      setIsShowNoticeBar(true);
      setIsShowBackground(true);
      setIsShowToggleButton(true);
      disableScroll(true);
    } else {
      acceptCookies([], ga.props.ga, config.props.config);
    }
  }, []);

  useEffect(() => {
    if (isDisableScroll) {
      intervalRef.current = window.setInterval(() => {
        document.body.style.overflow = 'hidden';
      }, 500);
    } else {
      clearInterval(intervalRef.current);
      document.body.style.overflow = '';
    }
  }, [isDisableScroll]);

  const disableScroll = isShow => {
    setIsDisableScroll(isShow);
  };

  return (
    <OverlayContext.Provider
      value={{
        isShowModal,
        setIsShowModal,
        isShowNoticeBar,
        setIsShowNoticeBar,
        isShowBackground,
        setIsShowBackground,
        isShowToggleButton,
        setIsShowToggleButton,
        disableScroll
      }}
    >
      {props.children}
    </OverlayContext.Provider>
  );
};

export const useContextOverlay = () => {
  const context = useContext(OverlayContext);
  return context;
};

export default OverlayState;

import { colorSet, elementColor } from './color';
import typography from './typography';
import zIndex from './zIndex';
import { breakPoint } from './breakPoint';

const getTheme = (colorList, fontFamily) => {
  const color = colorSet(colorList);

  return {
    typography,
    elementColor,
    color,
    zIndex,
    breakPoint,
    fontFamily
  };
};

export default getTheme;

import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

export const CheckBoxState = ({ items, render, value, onChange }) => {
  const [checkedList, setIsCheck] = useState(value);

  useEffect(() => {
    setIsCheck(value);
  }, [value]);

  const removeCheckedList = value => {
    const data = checkedList.filter(res => res !== value);
    setIsCheck(data);
    onChange(data);
  };

  const addCheckedList = value => {
    const data = [...checkedList, value];
    setIsCheck(data);
    onChange(data);
  };

  const setChecked = value => {
    if (checkedList.includes(value)) {
      removeCheckedList(value, checkedList);
    } else {
      addCheckedList(value, checkedList);
    }
  };

  const isChecked = value => {
    return checkedList.includes(value);
  };

  return items.map(res => {
    return render({
      setChecked,
      isChecked,
      title: res.title,
      description: res.description,
      key: res.key,
      icon: res.icon,
      checkedList
    });
  });
};

import { h } from 'preact';
import Modal from './index.view';
import { useContextOverlay } from '../OverlayContext';
import { acceptCookies, categories } from '../../service/cookie';
import { useContextGA } from '../../utils/context/gaTracker';
import { useContextConfig } from '../../utils/context/config';
import { getCookies } from '../../service/cookie';
import { useContextLanguage } from '../../locales';
import { useEffect, useState } from 'preact/hooks';
import { CSSTransition } from 'react-transition-group';

const ModalContainer = ({ onSave: onSaveProp }) => {
  const context = useContextOverlay();
  const language = useContextLanguage();
  const config = useContextConfig();
  const ga = useContextGA();

  useEffect(() => {
    setValue(getDefaultCookies());
  }, [context.isShowModal]);

  const onSave = checkedList => {
    acceptCookies(checkedList, ga.props.ga, config.props.config);

    context.setIsShowModal(false);
    context.setIsShowNoticeBar(false);
    context.setIsShowBackground(false);
    context.disableScroll(false);
    onSaveProp();
    setTimeout(() => {
      context.setIsShowToggleButton(false);
    }, 300);
  };

  const onCloseButtonCliked = () => {
    context.setIsShowModal(false);
  };

  const getDefaultCookies = () => {
    const data = getCookies()
      ? categories.map(res => {
          return getCookies()[res] ? res : null;
        })
      : [categories[0]];
    return data.filter(key => !!key);
  };
  const [value, setValue] = useState(getDefaultCookies());

  return (
    <CSSTransition in={context.isShowModal} timeout={300}>
      {state => {
        return (
          <Modal
            onSave={onSave}
            onCloseButtonCliked={onCloseButtonCliked}
            value={value}
            t={language.t}
            state={state}
          />
        );
      }}
    </CSSTransition>
  );
};

export default ModalContainer;

import { Component, h } from 'preact';
import styled from 'styled-components';
import CookieIcon from '../cookieIcon';

const Button = styled.button`
  cursor: pointer;
  ${({ theme }) => theme.typography.toggleButton()};
  color: ${({ theme }) => theme.color.secondary};
  width: 106px;
  box-sizing: border-box;
  padding: 45px 0px 16px;
  background-color: ${({ theme }) => theme.color.primary};
  border: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  outline: none;

  ::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 20;
    content: '';
    display: block;
  }

  span {
    font-size: 14px;
    display: block;
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 40px;
  z-index: 800;
  padding-top: 14px;
  transform: ${props =>
    props.transform ? 'translateY(0)' : 'translateY(calc(100% - 24px))'};
  transition: transform 0.3s ease-out;

  :hover {
    transform: translateY(0);
    transition: transform 0.5s ease-out;
  }
`;

const TextWrapper = styled.span``;

class ToggleButton extends Component {
  render() {
    const t = this.props.t;
    return (
      <ButtonWrapper
        onClick={() => this.props.toggleButton()}
        transform={this.props.transform}
      >
        <Button>
          <CookieIcon />
          {t('toggleButtonFirst')}
          <TextWrapper>{t('toggleButtonSecond')}</TextWrapper>
        </Button>
      </ButtonWrapper>
    );
  }
}

export default ToggleButton;

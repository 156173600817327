/* eslint-disable default-case */
import { Component, h } from 'preact';
import styled from 'styled-components';
import checkIcon from '../../assets/checked.svg';
import { CheckBoxState } from '../checkBox';
import { getCookieSettings } from '../../service/cookie';
import { useContextConfig } from '../../utils/context/config';

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
  overflow: auto;
  align-items: center;
  padding: 0px;
  display: flex;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  ${({ state }) => {
    switch (state) {
      case 'entering':
        return {
          opacity: 0,
          display: 'flex'
        };
      case 'entered':
        return {
          opacity: 1,
          display: 'flex'
        };
      case 'exiting':
        return {
          opacity: 0,
          display: 'flex'
        };
      case 'exited':
        return {
          opacity: 0,
          display: 'none'
        };
    }
  }};
`;

const Content = styled.div`
  position: relative;
  width: 470px;
  max-height: 584px;
  height: 96vh;
  border-radius: 2px;
  background: ${({ theme }) => theme.color.white};
  margin: auto;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakPoint.tablet} {
    width: 90%;
  }

  @media ${({ theme }) => theme.breakPoint.mobile} {
    width: 100%;
    max-height: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.whisper};
  flex: 0 0 auto;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.modalTitle()};
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
  padding: 0;
  text-align: left;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  right: 0;
  margin-right: 10px;
  position: absolute;
  transform: rotate(45deg);
  cursor: pointer;

  ::before {
    width: 18px;
    height: 2px;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.color.silver};
  }

  ::after {
    transform: translate(-50%, -50%) rotate(90deg);
    width: 18px;
    height: 2px;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    background: ${({ theme }) => theme.color.silver};
  }
`;

const Cookies = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 0 1 100%;
  overflow: auto;
  li:nth-last-of-type(1) {
    border-bottom: none;
  }
`;

const Cookie = styled.li`
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.whisper};
`;

const CookieRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const CookieTitle = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 24px;
  ${({ theme }) => theme.typography.cookieTitle()};
`;

const CookieDescription = styled.p`
  margin: 0;
  padding: 0 16px 15px 16px;
  display: block;
  ${({ theme }) => theme.typography.cookieDescription()}
`;

const Label = styled.label`
  background: ${props =>
    props.checked ? ({ theme }) => theme.color.primary : ''};
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: 1.5px solid ${({ theme }) => theme.color.primary};
  margin: 0 0 0 auto;
  position: relative;
  transition: all ease 0.3s;
  cursor: pointer;
  ::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url(${checkIcon}) no-repeat center 7px;
    content: '';
    display: block;
  }
`;

const TextLabel = styled.label`
  margin: 0 0 0 auto;
  position: relative;
  ${({ theme }) => theme.typography.cookieLabel()};
`;

const Footer = styled.div`
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  padding: 15px 16px 16px 16px;
  border-radius: 0 0 6px 6px;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 auto;
  border-top: 1px solid ${({ theme }) => theme.color.whisper};
`;

const SaveButton = styled.button`
  width: 229px;
  height: 45px;
  line-height: inherit;
  color: ${({ theme }) => theme.color.secondary};
  background: ${({ theme }) => theme.color.primary};
  border-radius: 8px;
  border-color: ${({ theme }) => theme.color.primary};
  text-align: center;
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.typography.button()}
  border: 0;
`;

class Modal extends Component {
  state = { checkedList: this.props.value };

  render() {
    const t = this.props.t;
    const config = useContextConfig();
    return (
      <Wrapper state={this.props.state}>
        <Content>
          <Header>
            <Title className="cookie-banner-menu-title">
              {t('modalTitle')}
            </Title>
            <CloseButton onClick={() => this.props.onCloseButtonCliked()} />
          </Header>
          <Cookies>
            {config.props.config.settingOptions.includes(
              getCookieSettings()[0].key
            ) ? (
              <Cookie>
                <CookieRow>
                  <CookieTitle
                    className="cookie-banner-cookie-menu-title"
                    icon={getCookieSettings()[0].icon}
                  >
                    {t(getCookieSettings()[0].title)}
                  </CookieTitle>
                  <TextLabel className="cookie-banner-cookie-menu-title">
                    {t('alwaysOn')}
                  </TextLabel>
                </CookieRow>
                <CookieDescription className="cookie-banner-cookie-menu-description">
                  {t(getCookieSettings()[0].description)}
                </CookieDescription>
              </Cookie>
            ) : null}

            <CheckBoxState
              value={this.props.value}
              onChange={value => {
                this.setState({ checkedList: value });
              }}
              items={getCookieSettings()
                .slice(1)
                .map(item => item)
                .filter(setting => {
                  return config.props.config.settingOptions.includes(
                    setting.key
                  );
                })}
              render={({
                isChecked,
                setChecked,
                title,
                description,
                key,
                icon
              }) => {
                return (
                  <Cookie>
                    <CookieRow>
                      <CookieTitle
                        className="cookie-banner-cookie-menu-title"
                        icon={icon}
                      >
                        {t(title)}
                      </CookieTitle>
                      <Label
                        onClick={() => {
                          setChecked(key);
                        }}
                        checked={isChecked(key)}
                      />
                    </CookieRow>
                    <CookieDescription className="cookie-banner-cookie-menu-description">
                      {t(description)}
                    </CookieDescription>
                  </Cookie>
                );
              }}
            />
          </Cookies>
          <Footer>
            <SaveButton
              className="cookie-banner-cookie-button"
              onClick={() => this.props.onSave(this.state.checkedList)}
            >
              {t('saveButton')}
            </SaveButton>
          </Footer>
        </Content>
      </Wrapper>
    );
  }
}

export default Modal;

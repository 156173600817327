import settingIcon from '../assets/setting.svg';
import meterIcon from '../assets/meter.svg';
import chartIcon from '../assets/chart.svg';
import shopIcon from '../assets/shop.svg';
import Cookies from 'js-cookie';

const getCookieSettings = () => {
  const data = [
    {
      title: 'essentialCookieTitle',
      description: 'essentialCookieseDescription',
      key: 'essential',
      icon: settingIcon
    },
    {
      title: 'performanceCookieTitle',
      description: 'performanceCookieDescription',
      key: 'performance',
      icon: meterIcon
    },
    {
      title: 'analyticsCookieTitle',
      description: 'analyticsCookieDescription',
      key: 'googleAnalytic',
      icon: chartIcon
    },
    {
      title: 'marketingCookieTitle',
      description: 'marketingCookieDescription',
      key: 'marketing',
      icon: shopIcon
    }
  ];
  return data;
};

const getCookies = () => {
  return Cookies.getJSON('gdpr-cookie-notice');
};

const categories = getCookieSettings().map(res => {
  return res.key;
});

let isGoogleAndFacebookHandle = false;

const acceptCookies = (setting, ga, config) => {
  const value = {
    essential: true,
    performance: true,
    googleAnalytic: true,
    marketing: true,
    date: new Date()
  };

  if (!setting) {
    Cookies.set('gdpr-cookie-notice', value, {
      expires: 30,
      domain: window.location.hostname
    });
    deleteCookies(getCookies(), ga, config);

    window.setInterval(() => {
      deleteCookies(getCookies(), ga, config);
    }, 3000);
  } else if (setting.length) {
    categories.map((key, index) => {
      value[categories[index]] = setting.includes(key);
    });
    Cookies.set('gdpr-cookie-notice', value, {
      expires: 30,
      domain: window.location.hostname
    });
    deleteCookies(value, ga, config);

    window.setInterval(() => {
      deleteCookies(value, ga, config);
    }, 3000);
  } else {
    deleteCookies(getCookies(), ga, config);

    window.setInterval(() => {
      deleteCookies(getCookies(), ga, config);
    }, 3000);
  }
};

const deleteCookies = (savedCookies, ga, config) => {
  for (var i = 0; i < categories.length; i++) {
    if (config[categories[i]] && !savedCookies[categories[i]]) {
      if (categories[i] === categories[2]) {
        // if it's google analytic, just skip it
        continue;
      }
      for (var ii = 0; ii < config[categories[i]].length; ii++) {
        Cookies.remove(config[categories[i]][ii], {
          domain: window.location.hostname
        });
        Cookies.remove(config[categories[i]][ii]);
      }
    }
  }
  if (config[categories[2]] && !isGoogleAndFacebookHandle) {
    if (savedCookies[categories[2]]) {
      enableGoogleAnalytic(ga, config);
      enableFacebookPixel(config);
    } else if (!savedCookies[categories[2]]) {
      disableGoogleAnalytic(config);
      disableFacebookPixel(config);
    }
    isGoogleAndFacebookHandle = true;
  }
};

const enableFacebookPixel = config => {
  if (config.fbq && config.fbPixel) {
    config.fbq('init', config.fbPixel);
    config.fbq('track', 'PageView');
  }
};

const disableFacebookPixel = config => {
  if (config.fbq && config.fbPixel) {
    config.fbq('consent', 'revoke');
    config.fbq('init', config.fbPixel);
    config.fbq('track', 'PageView');
  }
};

const enableGoogleAnalytic = (ga, config) => {
  if (config[categories[1]]) {
    window['ga-disable-' + config[categories[2]]] = false;

    try {
      if (ga) {
        ga('set', 'anonymizeIp', false);
        ga('send', 'pageview');
      }
    } catch (e) {
      console.warn('cookie robot', e.message);
    }
  }
};

const disableGoogleAnalytic = config => {
  const essentialCookies =
    config['essential'] && config['essential'].length
      ? config['essential']
      : [];

  const notRemovedCookies = [
    'gdpr-cookie-notice',
    '_gat',
    '_gid',
    '_ga',
    ...essentialCookies
  ];

  if (window.ga) {
    window.ga('set', 'anonymizeIp', true);
  }

  categories.map((res, index) => {
    if (index === 2) {
      return;
    }
    if (config[categories[index]]) {
      categories.map((doc, secIndex) => {
        notRemovedCookies.push(config[categories[index]][secIndex]);
      });
    }
  });

  const allCookies = Object.keys(Cookies.get());

  allCookies.map(key => {
    let isRemove = true;

    notRemovedCookies.map(doc => {
      if (doc === key) {
        isRemove = false;
      }
    });

    if (isRemove) {
      Cookies.remove(key);
    }
  });
};

const checkGoogleAnalytic = config => {
  if (!getCookies() || !getCookies()[categories[2]]) {
    disableGoogleAnalytic(config);
    return true;
  } else {
    return false;
  }
};

export {
  getCookieSettings,
  getCookies,
  categories,
  acceptCookies,
  checkGoogleAnalytic
};

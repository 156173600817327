import { Component, h } from 'preact';
import styled, { css } from 'styled-components';
import { useContextConfig } from '../../utils/context/config';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px 20px 20px 60px;
  display: flex;
  align-items: center;
  transition: ${props =>
    props.display ? '0.5s all ease-out' : '0.3s all ease-out'};
  z-index: 950;
  transform: ${props =>
    props.display ? 'translateY(0%)' : 'translateY(100%)'};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);

  @media ${({ theme }) => theme.breakPoint.tablet} {
    padding: 20px;
    display: block;
  }
`;

const Content = styled.div`
  padding-right: 16px;
  @media ${({ theme }) => theme.breakPoint.tablet} {
    padding: 0px;
    margin-bottom: 16px;
  }
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.typography.title()};

  @media ${({ theme }) => theme.breakPoint.tablet} {
    ${({ theme }) => theme.typography.moblieTitle()};
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.typography.description()};

  @media ${({ theme }) => theme.breakPoint.tablet} {
    ${({ theme }) => theme.typography.mobileDescription()};
  }
`;

const LinkWrapper = styled.span`
  margin-left: 4px;
`;

const LinkDivider = styled.span`
  width: 2px;
  height: 18px;
  background-color: ${({ theme }) => theme.color.gray77};
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  transform: translateY(4px);
`;

const Link = styled.a`
  ${({ theme }) => theme.typography.link()};
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  cursor: pointer;

  ${({ theme }) =>
    theme.color.hoverColor
      ? css`
          :hover {
            color: ${theme.color.hoverColor};
          }
        `
      : null}

  @media ${({ theme }) => theme.breakPoint.tablet} {
    ${({ theme }) => theme.typography.mobileLink()};
  }
`;

const ButtonWrapper = styled.nav`
  width: 229px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin: 0 0 0 auto;
  background: none;
  height: auto;
  padding: 0;
  cursor: pointer;

  @media ${({ theme }) => theme.breakPoint.tablet} {
    margin: auto;
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.typography.button()}
  width: 100%;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  background: ${({ theme }) => theme.color.primary};
  border: none;
  cursor: pointer;
`;

const ButtonText = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  border: 0;
`;

const PolicyWrapper = styled.div`
  display: contents;
`;

class NoticeBar extends Component {
  render() {
    const t = this.props.t;
    const config = useContextConfig();

    return (
      <Wrapper display={this.props.noticeBarDisplay}>
        <Content>
          <Title className="cookie-banner-title"> {t('noticeBarTitle')} </Title>
          <Description className="cookie-banner-description">
            {t('noticeBarDescription')}
            <LinkWrapper>
              {this.props.policyLink && this.props.showPolicyLink ? (
                <PolicyWrapper>
                  <Link
                    className="cookie-banner-external-link"
                    href={this.props.policyLink}
                    target="_blank"
                    style={{
                      marginRight: 8
                    }}
                  >
                    {t('policyButton')}
                  </Link>
                </PolicyWrapper>
              ) : null}
              {this.props.showSetting &&
              config.props.config.settingOptions.length > 0 ? (
                <Link
                  className="cookie-banner-external-link"
                  target="_blank"
                  onClick={() => this.props.onClickedManage()}
                >
                  {t('settingButton')}
                </Link>
              ) : null}
            </LinkWrapper>
          </Description>
        </Content>
        <ButtonWrapper onClick={() => this.props.onAccept()}>
          <Button>
            <ButtonText className="cookie-banner-button">
              {' '}
              {t('acceptButton')}{' '}
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default NoticeBar;
